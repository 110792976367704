"use strict";
import Route from "@/models/Route.js";
import store from "../store/index";
import features from "@/geojson/data.geojson";
import * as turf from "@turf/turf";
import TreeModel from "tree-model";

class Park {
  constructor(data) {
    this.name = data.name;
    this.desc = data.desc;
    this.audio = data.audio;
    this.nextRouteInfo = data.nextRouteInfo;
    this.routeInformation = data.routeInformation;
    this.prizes = data.prizes;
    this.introduction = data.introduction;
    this.help = data.help;
    this.warnings = data.warnings;
    this.about = data.about;
    this.discover = data.discover;
    this.homeTexts = data.homeText;

    this.parkArea = features.features.filter(
      (feature) => feature.geometry.type === "Polygon"
    )[0];


    this.routes = this.obtainNewRoutes(data);

    this.routesGeo = features.features.filter(
      (feature) => feature.geometry.type === "LineString"
    );

    this.pointsGeo = this.obtainSpots();
  }

  obtainNewRoutes(data) {
    var newRoute = [];
    data.routes.map((route) => {
      if (newRoute.filter((point) => point.id == route.id).length == 0) {
        newRoute.push({
          audio: route.audio,
          challenge: route.challenge,
          desc: route.desc,
          gallery: route.gallery,
          id: route.id,
          name: route.name,
          order: route.order,
          prize: route.prize,
          chalet: route.ar
        });
      }
    });

    return newRoute;
  }

  obtainSpots() {
    var spots = [];
    this.routesGeo.map((route) => {
      if (spots.filter((spot) => spot.properties.id == route.properties.startId).length == 0) {
        spots.push({
          properties: {
            id: route.properties.startId,
            name: route.properties.start,
            type: route.properties.startPopupType,
            order: route.properties.startOrder
          },
          geometry: { coordinates: route.geometry.coordinates[0] },
        });
      }

      if (spots.filter((spot) => spot.id == route.endId).length == 0) {
        spots.push({
          properties: {
            id: route.properties.endId,
            name: route.properties.end,
            type: route.properties.endPopupType,
            order: route.properties.endOrder,
          },
          geometry: {
            coordinates:
              route.geometry.coordinates[route.coordinates.length - 1],
          },
        });
      }
    });

    store.state.numRoutes = spots.length;

    return spots;
  }

  removeReactive(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  getSpotInfo(id) {
    let temp = this.pointsGeo.find((spot) => spot.properties.id == id);
    return temp;
  }

  getRouteWithId(id) {
    if (this.routes.length == 0) {
      return;
    }
    let routeData = this.routes.find((route) => route.id === id);
    if (routeData) {
      return new Route(routeData);
    }
    return null;
  }

  getNextRouteInfo(id) {
    if (id === "praca") {
      return this.nextRouteInfo["praca"];
    }
    return this.nextRouteInfo[id];
  }

  getRouteGallery(id) {
    return this.routes.find((r) => r.id == id).gallery;
  }

  getRoutesLeft(id) {
    let currentRoute = this.routes.filter((r) => r.id == id)[0];
    var routes = [];
    var lastRoute;
    var initialOrder = currentRoute.order;
    do {
      initialOrder += 1;
      if (initialOrder >= this.routes.length) {
        initialOrder = 1;
      }

      lastRoute = new Route(this.routes.find((r) => r.order == initialOrder));
      if (!lastRoute.id.includes(currentRoute.id)) {
        routes.push(lastRoute.id);
      }
    } while (
      lastRoute.id != currentRoute.id &&
      !lastRoute.id.includes(currentRoute.id)
    );
    return routes;
  }

  getNextRoute(currentOrder) {
    let newOrder = currentOrder + 1;
    if (newOrder >= store.state.numRoutes) {
      return null;
    }

    let park = store.state.parkData;
    let route = park.routes.find((route) => {
      return route.order == newOrder;
    });
    return route;
  }

  // Next Route Solution Methods
  getAllRoutesBySpot() {
    // get all the routes for each spot
    var result = {};
    this.pointsGeo.forEach((spot) => {
      result[spot.properties.id] = this.routesGeo.filter((r) => {
        return (
          r.properties.startId == spot.properties.id ||
          r.properties.endId == spot.properties.id
        );
      });
    });

    // save all the inverse routes
    Object.keys(result).forEach((spot) => {
      let currentArray = result[spot];
      var newArray = this.obtainReversedRoutes(currentArray);
      result[spot] = newArray;
    });

    return result;
  }

  obtainReversedRoutes(array) {
    var reversedArray = this.deepCopy(array);
    reversedArray.forEach((route) => {
      let newRoute = this.deepCopy(route);
      let newId = route.properties.id.split("-").reverse().join("-");
      let newEndId = route.properties.startId;
      let newEnd = route.properties.start;
      let newEndType = route.properties.startPopupType;
      let newCoords = this.deepCopy(route.geometry.coordinates).reverse();

      newRoute.properties.id = newId;
      newRoute.properties.startId = newRoute.properties.endId;
      newRoute.properties.start = newRoute.properties.end;
      newRoute.properties.startPopupType = newRoute.properties.endPopupType;
      newRoute.properties.endId = newEndId;
      newRoute.properties.end = newEnd;
      newRoute.properties.endPopupType = newEndType;
      newRoute.geometry.coordinates = newCoords;

      reversedArray.push(newRoute);
    });

    return reversedArray;
  }

  deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  startChecking(from, to) {
    var spotsToVerify = [from];
    var verifiedSpots = [];
    var tree = new TreeModel();
    var rootNode;
    var inmediateRoutes;

    do {
      let currentFrom = spotsToVerify[0];
      if (currentFrom == null) {
        return;
      }

      spotsToVerify = this.removeItemInArray(spotsToVerify, spotsToVerify[0]);
      let res = this.obainRoute(currentFrom, to, verifiedSpots);
      spotsToVerify = spotsToVerify.concat(res.spotsToVerify);
      verifiedSpots = verifiedSpots.concat(res.verifiedSpots);

      if (!rootNode) {
        if (res.linkedRoutesToFrom.length > 0) {
          inmediateRoutes = res.linkedRoutesToFrom;
        } else {
          let nodes = res.spotsToVerify.map((spot) => {
            return {
              id: spot.properties.id,
              children: null,
            };
          });

          let linked = res.linkedRoutesToFrom.map(
            (linked) => linked.properties.id
          );

          rootNode = tree.parse({
            id: from.properties.id,
            linkedRoutes: linked,
            children: nodes,
          });
        }
      } else {
        if (res.spotsToVerify) {
          let spotsToVerify = res.spotsToVerify.map((spot) => {
            return tree.parse({
              id: spot.properties.id,
              children: null,
            });
          });

          let node = this.findNode(rootNode, currentFrom.properties.id);
          spotsToVerify.forEach((n) => {
            node.addChild(n);
          });
        }

        let node = this.findNode(rootNode, currentFrom.properties.id);
        node.linkedRoutes = res.linkedRoutesToFrom.map(
          (linked) => linked.properties.id
        );
      }
    } while (spotsToVerify.length > 0 && inmediateRoutes == undefined);

    if (inmediateRoutes) {
      var routesArray = [];
      inmediateRoutes.forEach((r) => {
        let temp = r.properties.id.split("-");
        routesArray.push(temp);
      });
      return routesArray;
    } else {
      let withLinked = rootNode.all(function (node) {
        return node.linkedRoutes != undefined && node.linkedRoutes.length > 0;
      });

      var routesResults = [];
      withLinked.forEach((linked) => {
        var path = linked.getPath();
        path = path.map((p) => {
          if (p.linkedRoutes != undefined && p.linkedRoutes.length > 0) {
            let route = p.linkedRoutes[0].split("-");
            return route;
          } else {
            return p.model.id;
          }
        });
        routesResults.push(path.flat());
      });

      return routesResults;
    }
  }

  findNode(root, id) {
    return root.first(function (node) {
      return node.model.id == id;
    });
  }

  obainRoute(from, to, verifiedSpots) {
    let res = {
      verifiedSpots: [],
      linkedRoutesToFrom: null,
      spotsToVerify: null,
    };

    var spotsLinkedToFrom = this.getSpotsLinkedTo(
      from.properties.id,
      verifiedSpots
    );
    res.linkedRoutesToFrom = this.getRoutesWithFinalPoint(
      [from.properties.id],
      to.properties.id
    );

    res.verifiedSpots.push(from.properties.id);

    if (res.linkedRoutesToFrom.length == 0) {
      var spotsLinkedArray = spotsLinkedToFrom.map((spot) =>
        this.getSpotInfo(spot)
      );
      res.spotsToVerify = spotsLinkedArray;
    } else {
      res.spotsToVerify = [];
    }

    return res;
  }

  removeItemInArray(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  getDistancesToAllSpotsLeft(currentId) {
    let currentSpot = this.pointsGeo.find((p) => p.properties.id == currentId);
    var distances = Array();
    this.pointsGeo.forEach((point) => {
      let distance = this.getDistanceFromLatLonInKm(
        currentSpot.geometry.coordinates[0],
        currentSpot.geometry.coordinates[1],
        point.geometry.coordinates[0],
        point.geometry.coordinates[1]
      );

      distances.push({
        from: currentSpot,
        to: point,
        distance: distance,
        order: point.properties.order,
      });
    });

    //Order by spot order
    distances = this.sortArrayDistancesBy(distances, "order");
    return distances;
  }

  sortArrayDistancesBy(array, attr) {
    return array.sort(function compare(a, b) {
      if (a[attr] < b[attr]) {
        return -1;
      }
      if (a[attr] > b[attr]) {
        return 1;
      }
      return 0;
    });
  }

  sortRouteByDistances(array, attr) {
    return array.sort(function compare(a, b) {
      if (a[attr] < b[attr]) {
        return -1;
      }
      if (a[attr] > b[attr]) {
        return 1;
      }
      return 0;
    });
  }

  getSpotsLinkedTo(fromId, spotsToAvoid) {
    var resultSet = new Set();
    let spotRoutes = this.getAllRoutesBySpot();

    spotRoutes[fromId].forEach((route) => {
      if (
        route.properties.startId !== fromId &&
        !spotsToAvoid.includes(route.properties.startId)
      ) {
        resultSet.add(route.properties.startId);
      }
      if (
        route.properties.endId !== fromId &&
        !spotsToAvoid.includes(route.properties.endId)
      ) {
        resultSet.add(route.properties.endId);
      }
    });
    let arr = [...resultSet];
    return arr;
  }

  getRoutesWithFinalPoint(spotsArray, finalSpotId) {
    var result = [];
    var spotRoutes = this.getAllRoutesBySpot();
    spotsArray.forEach((spot) => {
      let routes = spotRoutes[spot];
      let routesWithCorrectEndpoint = routes.filter((r) => {
        return r.properties.endId == finalSpotId;
      });
      result = result.concat(routesWithCorrectEndpoint);
    });
    return result;
  }

  fixWrongProperties(route, from, to) {
    var result = this.deepCopy(route);
    result.properties.id = `${from.properties.id}-${to.properties.id}`;
    result.properties.start = from.properties.name;
    result.properties.startId = from.properties.id;
    result.properties.startPopupType = from.properties.type;
    result.properties.end = to.properties.name;
    result.properties.endId = to.properties.id;
    result.properties.endPopupType = to.properties.type;
    return result;
  }

  arrayIncludesCoord(arr, coord) {
    if (
      this.compareCoordArrays(
        arr.geometry.coordinates[0],
        coord.geometry.coordinates
      )
    ) {
      return "start";
    } else if (
      this.compareCoordArrays(
        arr.geometry.coordinates[arr.geometry.coordinates.length - 1],
        coord.geometry.coordinates
      )
    ) {
      return "end";
    } else {
      return null;
    }
  }

  compareCoordArrays(arr1, arr2) {
    return arr1.every(function (element, index) {
      return element === arr2[index];
    });
  }

  buildRoute(spots) {
    var spotRoutes = this.getAllRoutesBySpot();
    var coordinates = [];
    var baseRoute;
    for (let i = 0; i < spots.length; i++) {
      if (spots[i + 1] == undefined) {
        break;
      }

      const initialSpot = spots[i];
      const endSpot = spots[i + 1];

      let route = spotRoutes[initialSpot].find(
        (route) => route.properties.id === `${initialSpot}-${endSpot}`
      );

      if (!baseRoute) {
        baseRoute = route;
      }
      coordinates = coordinates.concat(route.geometry.coordinates);
    }

    let startSpot = this.getSpotInfo(spots[0]);
    let endSpot = this.getSpotInfo(spots[spots.length - 1]);
    baseRoute = this.fixWrongProperties(baseRoute, startSpot, endSpot);

    baseRoute.geometry.coordinates = coordinates;
    return baseRoute;
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  getMeters(km) {
    return turf.convertDistance(km, "kilometers", "meters");
  }

  getRouteDistance(routeCoords) {
    let distance = 0.0;
    for (let i = 0; i < routeCoords.length; i++) {
      if (!routeCoords[i + 1]) {
        break;
      }
      const coordStart = routeCoords[i];
      const coordEnd = routeCoords[i + 1];
      const segmentDistance = this.getDistanceFromLatLonInKm(
        coordStart[0],
        coordStart[1],
        coordEnd[0],
        coordEnd[1]
      );
      distance += segmentDistance;
    }

    return distance;
  }
}

export default Park;
