<template>
      <div class="challenge">
        <div v-if="type == 'quiz' && !challengeFinished" class="wrapper">
          <Quiz
            :challengeId="challenge.id"
            :question="question"
            :answers="answers"
            :onFinished="finishedChallenge"
          ></Quiz>
        </div>
        <div v-if="type == 'audio' && !challengeFinished" class="wrapper">
          <AudioTrivia :challengeId="challenge.id" :question="question" :answers="answers" :onFinished="finishedChallenge">
          </AudioTrivia>
        </div>
        <div v-if="type == 'images' && !challengeFinished" class="wrapper">
          <ImageTrivia
            :challengeId="challenge.id"
            :question="question"
            :answers="answers"
            :onFinished="finishedChallenge"
          ></ImageTrivia>
        </div>
        <div v-if="type == 'imagesv2' && !challengeFinished" class="wrapper">
          <ImageTriviaV2
            :challengeId="challenge.id"
            :question="question"
            :answers="answers"
            :onFinished="finishedChallenge"
          ></ImageTriviaV2>
        </div>
        <div v-if="type == 'puzzle' && !challengeFinished" class="wrapper">
          <ImagePuzzle
            :challengeId="challenge.id"
            :challenge="challenge"
            :question="question"
            :pieces="pieces"
            :onFinished="finishedChallenge"
          ></ImagePuzzle>
        </div>
    
        <transition :name="modalTransition ? 'slide-fade' : ''">
          <challenge-result
            v-if="challengeFinished"
            :hasNextRoute="this.$store.state.nextRoute != null"
            :correct="showPrize"
            :advance="advance"
            :playAudio="playAudio"
          ></challenge-result>
        </transition>
      </div>
    </template>
    
    <script>
    import Park from "@/models/Park.js";
    import ChallengeResult from "@/components/ChallengeResult.vue";
    import Quiz from "@/components/Quiz.vue";
    import AudioTrivia from "@/components/AudioTrivia.vue";
    import ImageTrivia from "@/components/ImageTrivia.vue";
    import ImageTriviaV2 from "@/components/ImageTriviaV2.vue";
    import ImagePuzzle from "@/components/ImagePuzzle.vue";
    
    import { Howl } from "howler";
    const $ = require('jquery');
    
    export default {
      name: "Challenge",
      components: {
        ChallengeResult,
        Quiz,
        ImageTrivia,
        AudioTrivia,
        ImagePuzzle,
        ImageTriviaV2,
    },
      watch: {
        "$i18n.locale": function () {
          this.localizedData();
        },
        challengeFinished: function () {
          this.$root.$emit("initPlayerWith", this.challengeFinishedAudio);
        },
      },
      mounted() {
        this.localizedData();
        this.park = new Park(this.$store.state.parkData);
    
          let isThisChallengeFinished = this.$store.state.finishedChallenges.find(
            (el) => el.challenge === this.challenge.id
          );
          let isThisChallengeFinishedP = this.$store.state.finishedChallengesPalacio.find((el) => el.challenge == this.challenge.id);
          if (isThisChallengeFinished !== undefined) {
            this.modalTransition = false;
            this.challengeFinished = true;
            this.showPrize = isThisChallengeFinished.correct;
    
            var minPoints = this.$store.getters.minPointsComplete;
            console.log("Min points", minPoints);
          } else {
            if(isThisChallengeFinishedP && this.$store.state.currentRoute.id == "palacio"){
                this.modalTransition = false;
                this.challengeFinished = true;
            }else{
                this.modalTransition = true;
            }
          }
      },
      data() {
        return {
          park: null,
          routeId: null,
          challenge: null,
          challengeFinished: false,
          showPrize: null,
          question: "",
          answers: [],
          pieces: {},
          type: "answer",
          selected: null,
          modalTransition: true,
          routeShown: false,
          currentSound: null,
        };
      },
      computed: {
        challengeAudio() {
          return this.challenge.audio;
        },
        challengeFinishedAudio() {
          return this.challenge.finishedAudio;
        },
        questHasMoreChallenges() {
          return (
            this.$store.state.finishedChallenges.length !==
            this.$store.state.parkData.prizes.length
          );
        },
      },
      methods: {
        localizedData() {
          this.routeId = this.$store.state.currentRoute.id;
          let park = new Park(this.$store.state.parkData);
          this.$store.commit("setParkData", park);
          let route = park.getRouteWithId(this.routeId);
          this.$store.commit("currentRoute", route);
          let challenge = route.challenge;
          this.challenge = challenge;
    
          if(this.routeId == "palacio"){
            var idP = this.$store.state.challengePalacio.id;
            var actual = this.$store.state.currentRoute.challenge.quizes.find(item => item.id === idP);
            this.challenge = actual;
            challenge = actual;
          }
    
          this.type = challenge.type;
          this.$store.commit("currentChallenge", challenge);
          
          if (this.type != "puzzle") {
            this.answers = this.shuffle(challenge.answers);
          } else {
            this.pieces = challenge.pieces;
          }
          this.question = challenge.question;
          this.$root.$emit("initPlayerWith", this.challengeAudio);
        },
        shuffle(a) {
          for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
          }
          return a;
        },
        containsObject(obj, list) {
          return list.find((item) => item.id === obj.id) !== undefined;
        },
        advance() {
          if (this.$store.getters.allChallengesDone || this.$store.getters.minPointsComplete) {
            this.$router.push("Complete");
          } else {
            if (
              this.$store.state.nextRoute == null ||
              this.$store.state.nextRoute == undefined
            ) {
              this.$router.push("LastSpot");
            } else {
              this.setRouteAsShown(this.challenge.id);
              this.$router.push("NextRoute");
            }
          }
    
          if (this.currentSound != null) {
            this.currentSound.stop();
            this.currentSound.unload();
            this.currentSound = null;
          }
        },
        setRouteAsShown(id) {
          this.$store.commit("setRouteAsShown", id);
        },
        finishedChallenge(isCorrect) {
          if(this.routeId == "palacio"){
            this.challengeFinished = true;
            this.showPrize = isCorrect;
            console.log("cantidad palacio",this.$store.state.finishedChallengesPalacio.length);
            if(this.$store.state.finishedChallengesPalacio.length == 3){
              this.setRouteAsFinished(this.routeId);
            }
          }else{
            this.challengeFinished = true;
            this.showPrize = isCorrect;
            this.setRouteAsFinished(this.routeId);
          }
        },
        setRouteAsFinished(id) {
          if (this.$store.getters.routeWasFinished(id)) {
            return;
          }
          this.$store.commit("setRouteAsFinished", id);
        },
        playAudio() {
          let correct = this.$store.state.currentChallenge.answers.find(
            (ans) => ans.isCorrect
          );
          var audio = correct.audio;
          $(".audioBtn img").attr("src", require("@/assets/bocina.png")).removeClass("pulse");
          $(".audioBtn img").attr("src", require("@/assets/bocina_seleccion.png")).addClass("pulse");
          if (this.currentSound != null) {
            this.currentSound.stop();
            this.currentSound.unload();
            this.currentSound = null;
          }
    
          this.currentSound = this.loadAudio(audio);
          this.currentSound.play();
          this.$store.commit("playAudio", true);
        },
        loadAudio(audioName) {
          console.log("will load", audioName);
          return new Howl({
            src: ["audios/" + audioName],
            loop: false,
            volume: 0.8,
            onload: function () {
              console.log("loaded!");
            },
            onend: function () {
              $(".audioBtn img").attr("src", require("@/assets/bocina.png")).removeClass("pulse");
              this.$store.commit("playAudio", true);
            }
          });
        },
      },
      beforeDestroy() {
        if (this.currentSound != null) {
          this.currentSound.stop();
          this.currentSound.unload();
          this.currentSound = null;
        }
      }
    };
    </script>
    
    <style lang="scss" scoped>
    .challenge {
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
    
      .wrapper {
        position: relative;
        display: flex;
        flex: 1;
        flex-flow: column;
        align-items: center;
        margin: 7.5rem 1.7rem 0rem 1.7rem;
        overflow: auto;
      }
    
      .slide-fade-enter-active {
        transition: all 0.3s ease-out;
      }
    
      .slide-fade-enter {
        transform: translateY(40px);
        opacity: 0;
      }
    }
    </style>
    