<template>
  <div class="scanner">
    <qrcode-stream @init="onInit" @decode="onDecode"></qrcode-stream>
    <span class="instruction">{{ $t("scanner-title") }}</span>
    <img
      class="marker"
      src="img/04_marcador/marcador.png"
      alt="marker"
    />

    <button class="round-btn close" @click="$router.go(-1)">
      <img
        src="img/02_generales/cerrar.png"
        alt="close button"
      />
    </button>

    <AppAlert ref="appAlert" />

    <loading :loading="showLoading"></loading>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import loading from "@/components/loading.vue";
import Park from "@/models/Park.js";
import NextRoute from "../js/NextRoute.js";

import { Analytic } from "./../../public/js/analytics/Analytic.js";
import AppAlert from "../components/AppAlert.vue";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Scanner",
});

export default {
  mounted() {
    this.parkData = new Park(this.$store.state.parkData);
  },
  data() {
    return {
      parkData: null,
      showLoading: true,
    };
  },
  beforeRouteLeave(to, from, next) {
    analytic.registerEvent("Scanner", "closed");
    next();
  },
  components: {
    QrcodeStream,
    loading,
    AppAlert,
  },
  methods: {
    async onInit(promise) {
      try {
        this.showLoading = true;
        await promise;
      } catch (err) {
        console.log("error: ", err);
        if (err.name === "NotAllowedError") {
          //   console.log("user denied camera access permisson");
          //   console.log("Camera access is needed to turn on the QR scanner.");
          setTimeout(() => {
            this.$refs.appAlert.showAlert({
              type: "message",
              msg: this.$t("QRScanner.notAllowed"),
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.$refs.appAlert.showAlert({
              type: "message",
              msg: this.$t("QRScanner.error"),
            });
          }, 1000);
        }
      } finally {
        setTimeout(() => {
          this.showLoading = false;
        }, 500);
      }
    },
    onDecode(url) {
      let routeId = this.getLastPath(url);
      let route = this.parkData.getRouteWithId(routeId);
      console.log("scanner",route);
      console.log("QR",url);
      console.log(routeId);
      if(route != null){
        this.$store.commit("setRouteId", routeId);
        this.$store.commit("currentRoute", route);
        // Helper objext to save nextroute into store
        let nextRouteHelper = new NextRoute(this.route);
        nextRouteHelper.getNextRouteData();
        this.$router.push("route");
        localStorage.currentRoute = routeId;
      }
    },
    getLastPath(url) {
      return url.substring(url.lastIndexOf("/") + 1);
    },
  },
};
</script>

<style lang="scss">
.scanner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(58, 58, 58);

  .instruction {
    position: absolute;
    left: 0;
    right: 0;
    top: 4.5rem;
    margin: auto;
    color: white;
    font-size: 1.8rem;
    font-family: "Lato";
  }

  .marker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 55%;
    max-width: 16rem;
  }

  .close {
    left: unset;
    right: 1rem;
  }
}
</style>
