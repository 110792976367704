<template>
  <div class="route">
    <div v-if="route" class="wrapper">
      <h2 class="title">{{ name }}</h2>
      <div class="content-wrapper">
        <p v-if="information" v-html="information"></p>

        <div class="button" v-if="routeId == 'praca' || url =='/praca' || url =='/route?id=praca'" v-html="this.condessaButton" @click="openCondessa"></div>

        <div v-if="gallery" class="gallery">
          <div
            v-for="(chunk, index) in getGalleryLayoutContainers"
            :key="index"
            class="layout-wrapper"
            :class="{ special: gallery.length <= 2 }"
          >
            <div v-for="(item, i) in chunk" :key="i" class="item">
              <img
                :src="getImage(item.src)"
                :data-id="i"
                class="image"
                @click="openGallery"
              />
            </div>
          </div>
          <figure class="galleryImg">
            <img src="img/galeria_btn.png" alt="">
          </figure>
        </div>
      </div>

      <big-button v-if="challenge && !onlyDetail" :onClick="advance">{{
        this.isThisChallengeFinished ? $t("next") : $t("challenge")
      }}</big-button>
    </div>

    <transition name="fade" mode="out-in">
      <Gallery
        :images="plainGallery"
        :index="selectedImageIndex"
        v-if="galleryIsShown"
      ></Gallery>
    </transition>

    <transition name="fade" mode="out-in">
      <Reproductor
        v-if="reproductorIsShown"
      ></Reproductor>
    </transition>

    <transition name="fade" mode="out-in">
      <Condessa
        v-if="condessaIsShown"
      ></Condessa>
    </transition>

    <button v-if="onlyDetail" class="close round-btn" @click="goBack">
      <img
        src="img/02_generales/cerrar.png"
        alt="close button"
      />
    </button>
  </div>
</template>

<script>
import Park from "@/models/Park.js";
import Route from "@/models/Route.js";
import NextRoute from "../js/NextRoute.js";
import BigButton from "@/components/BigButton.vue";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import Gallery from "@/components/Gallery.vue";
import Reproductor from "@/components/Reproductor.vue";
import Condessa from "@/components/Condessa.vue";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Route",
});

export default {
  name: "Route",
  components: {
    BigButton,
    Gallery,
    Reproductor,
    Condessa,
  },
  data() {
    return {
      routeId: null,
      name: null,
      park: null,
      route: null,
      information: null,
      gallery: null,
      challenge: null,
      currentIndex: null,
      isThisChallengeFinished: false,
      onlyDetail: false,
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      url: null,
      chalet: null,
      reproductorIsShown: null,
      condessaIsShown: null,
      condessaButton: null,
      id: null,
    };
  },
  watch: {
    "$store.state.locale": function () {
      let routeId = this.$store.state.currentRoute.id;
      this.route = this.$store.state.parkData.getRouteWithId(routeId);
      this.$store.commit("currentRoute", this.route);
      // Helper objext to save nextroute into store
      let nextRouteHelper = new NextRoute(this.route);
      nextRouteHelper.getNextRouteData();
      this.updateData();
      this.condessaButton = this.$t("selfie.button")
      console.log(this.$t("selfie.button"));
    },
  },
  mounted() {
    this.park = new Park(this.$store.state.parkData);
    this.url = location.pathname+location.search;
    console.log(this.url);
    var availableRoutes = this.$store.state.parkData.routes.map((route) => route.id);
    console.log(availableRoutes);
    this.onlyDetail = Object.keys(this.$route.query).length !== 0;
    console.log(this.$store.state.routeId);
    var route0 = new Route(this.park.getRouteWithId(this.$store.state.routeId));
    console.log("pruebaaaa",route0);
    this.$store.commit("currentRoute", route0);
    if (this.onlyDetail) {
      // set the need of sidebar when go back
      let routeEl = document.querySelector(".route");
      routeEl.style.zIndex = 1001;
      this.$store.commit("setSidebarNeeded", true);
      let query = this.$route.query;
      this.routeId = query.id;
    } else {
      if (!this.$store.state.routeId || !availableRoutes.includes(this.$store.state.currentRoute.id)) {
        this.$router.replace("scanner");
        return;
      }
      this.routeId = this.$store.state.routeId;
    }

    if (this.routeId == "praca") {
      analytic.registerEvent("Route", "praca");
    } else {
      analytic.registerEvent("Route", this.routeId);
    }

    console.log(this.routeId);
    this.route = new Route(this.park.getRouteWithId(this.routeId));
    console.log("pruebaaaa",this.route);
    this.$store.commit("currentRoute", this.route);
    let nextRouteHelper = new NextRoute(this.route);
    nextRouteHelper.getNextRouteData();
    this.updateData();
    this.condessaButton = this.$t("selfie.button")

    //var entradaState = this.$store.state.entradaState;
  },
  computed: {
    fixedGallery() {
      return this.gallery.map((item) => {
        return {
          id: item.id,
          src: require(`@/assets/images/${item.src}`),
          description: item.description,
        };
      });
    },
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/images/${item.src}`);
      });
    },
    routeAudio() {
      return this.route.audio;
    },
    getGalleryLayoutContainers() {
      let galleryCopy = this.gallery;
      var containers = [];
      for (var i = 0; i < galleryCopy.length; i += 6) {
        containers.push(galleryCopy.slice(i, i + 3));
      }
      return containers;
    },
  },
  methods: {
    advance() {
      let challenge = this.$store.state.currentRoute.challenge;
      this.$store.commit("currentChallenge", challenge);
      if (this.isThisChallengeFinished) {
        if (this.$store.getters.allChallengesDone || this.$store.getters.minPointsComplete) {
          this.$router.push("Complete");
        } else {
          if (!this.$store.state.nextRoute) {
            this.$router.push("LastSpot");
          } else {
            this.$router.push("NextRoute");
          }
        }
      } else {
        this.$router.push("Challenge");
      }
    },
    updateData() {
      this.$nextTick();
      this.name = this.route.name;
      this.information = this.route.desc;
      this.gallery = this.route.gallery;
      this.challenge = this.route.challenge;
      this.isThisChallengeFinished = this.checkIfChallengeFinished();
      this.chalet = this.route.ar;
      this.url = location.pathname+location.search;

      this.$root.$emit("initPlayerWith", this.routeAudio);
    },
    checkIfChallengeFinished() {
      let ocurrences = this.$store.state.finishedChallenges.find(
        (el) => el.challenge === this.route.challenge.id
      );

      console.log(this.route.challenge.id);

      return ocurrences != undefined;
    },
    getImage(name) {
      return require(`@/assets/images/${name}`);
    },
    openGallery(e) {
      this.selectedImage = e.target.src;
      this.selectedImageIndex = e.target.getAttribute("data-id");
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
    openReproductor() {
      this.reproductorIsShown = true;
    },
    closeReproductor() {
      this.reproductorIsShown = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openCondessa(){
      this.condessaIsShown = true;
    },
    closeCondessa(){
      this.condessaIsShown = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.route {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-color: var(--app-bg);
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 0rem 1.7rem;
    overflow: auto;
    padding-top: 7.5rem;

    .logo {
      position: relative;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: relative;
        height: auto;
        width: 100%;
      }
    }

    .title {
      font-size: 29px;
      text-align: left;
      align-self: flex-start;
      //text-transform: uppercase;
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
      font-weight: normal;
      color: #5c5c5c;
      font-family: "Myriad-bold";
    }

    .content-wrapper {
      flex: 1;
      padding: 1rem 0 1rem 0;
      overflow-y: auto;
      overflow-x: hidden;
      mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );

      p {
        text-align: initial;
        font-size: 1.25rem;
        line-height: 2rem;
        font-family: "Lato";
        color: #272727;
      }

      .thumbVideo{
        position: relative;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .thumb{
          width: 100%;
        }

        .btnPlay{
          width: 3rem;
          height: 3rem;
          position: absolute;
          
          img{
            animation: pulse 2s linear infinite;
          }
        }

        img{
          width: 100%;
          object-fit: contain;
        }
      }

      .button {
        position: relative;
        width: max-content;
        background-color: #162B44;
        color: white;
        font-family: "Myriad-bold";
        border-radius: 6px;
        text-align: center;
        box-shadow: 0px 4px 4px 0px rgab(0,0,0,0.25);
        transition: all 0.2s ease;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        padding: 0.8rem 1rem;
        //color: white;
        margin: 0 auto;
        margin-bottom: 2rem;
      }

      .gallery {
        margin: 0 auto;
        position: relative;

        .galleryImg{
          width: 2.6rem;
          height: 2.6rem;
          pointer-events: none;
          position: absolute;
          bottom: 0;
          right: 0.25rem;
          animation: pulse 2s linear infinite;

          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        /* Gallery */
        .layout-wrapper {
          position: relative;
          //display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          gap: 0.5rem;

          &:not(:first-of-type) {
            margin-top: 0.5rem;
          }

          &.special {
            .item {
              &:nth-child(3n + 1) {
                grid-column: 1;
                grid-row: 1 / span 1;
              }
            }
          }

          .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 12rem;
            overflow: hidden;

            .image {
              position: absolute;
              width: 100%;
              height: 100%;
              margin: 0;
              object-fit: cover;
              object-position: center;
            }

            &:nth-child(3n + 1) {
              grid-column: 1;
              grid-row: 1 / span 2;
            }

            &:nth-child(3n + 2) {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
              display: none;
            }

            &:nth-child(3n + 3) {
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
              display: none;
            }
            &:nth-child(3n + 4) {
              display: none;
            }
            &:nth-child(3n + 5) {
              display: none;
            }
          }
        }
      }
    }
  }

  .close {
    left: unset;
    right: 1rem;
  }
}

@keyframes pulse{
  0%{
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50%{
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
  }
  100%{
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
