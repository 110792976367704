<template>
  <transition name="fade">
    <div v-if="showSplash" name="fade" class="splash">
      <img
        class="bg-video"
        src="img/03_onboarding/on_logo.png">
      <transition name="bounce">
        <div class="splashText" :key="text" v-html="text"></div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  name: "splash",
  data() {
    return {
      phase: 0,
      loading: true,
      text: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("setShowSplash", false);
    }, 4000);

    setTimeout(() => {
      this.text = this.$t("splash.text");
    }, 1500);
  },
  computed: {
    showSplash() {
      return this.$store.state.showSplash;
    },
    setText(){
      return this.$t("splash.text")
    }
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter {
  opacity: 0;
}
.splash {
  position: absolute;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  // background-color: #537b33;

  .bounce-enter-active {
    animation: bounce-in 1s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.8s reverse;
  }

  .splashText {
    position: absolute;
    color: white;
    bottom: 30%;
    font-size: 20px;
    //animation: fadeIn 5s linear 2s;
    width: 70%;
    font-family: "Myriad-bold";
    color: #272727;
  }

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    /*&.one {
      background-image: url("~@/assets/01_splash/foto_00/IMG_0933.jpg");
    }
    &.two {
      background-image: url("~@/assets/01_splash/foto001/IMG_0504 1.jpg");
    }*/
  }

  .logo_s {
    width: 130px;
    height: 242px;
    z-index: 1000;
  }

  .logo_u {
    width: 150px;
    height: 150px;
    z-index: 1000;
  }
}

// video version
.splash {
  background-color: white;

  .bg-video{
    width: 65%;
    animation: shine 2.5s;
    margin-bottom: 5rem;
  }

  video {
    width: 100%;
  }
}

@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: scale(0.8);
  }
  10%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shine{
  0%{
    opacity: 0.5;
    transform: scale(1);
  }
  50%{
    opacity: 0.7;
    transform: scale(1.2);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
</style>
