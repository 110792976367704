<template>
    <div class="info">
      <button class="round-btn round-btn-help" @click="closeModal">
        <img
          src="img/02_generales/cerrar.png"
          alt="close button"
        />
      </button>
  
      <div v-if="section" class="wrapper">
        <h2 class="title">
          {{ $t("s-" + section) }}
        </h2>
        <div class="content-wrapper" v-html="sectionDescription"></div>
      </div>
    </div>
  </template>
  
  <script>
  const $ = require('jquery');
  window.$ = $;
  import { Analytic } from "../../public/js/analytics/Analytic.js";
  var options = {
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Info",
  };
  var analytic = new Analytic(options);
  export default {
    name: "Info",
    async mounted() {
      await this.$nextTick();
    },
    deactivated() {
      analytic.registerEvent(`Info: ${this.section}`, "closed");
    },
    computed: {
      sectionDescription() {
        analytic.registerEvent(`Info: ${this.section}`, "open");
        return this.$store.state.parkData[this.section].text;
      },
    },
    data() {
      return {
        parkData: null,
        section: "help",
      };
    },
    methods: {
      closeModal() {
        this.$router.go(-1);
      }
    },
  };
  </script>
  
  <style lang="scss">
  .info {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--app-bg);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;

    .round-btn-help{
      left: unset;
      right: 1rem;
    }
  
    .wrapper {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column;
      align-items: center;
      margin: 0rem 1.7rem;
      overflow: hidden;
      max-height: 90%;
  
      .title {
        font-family: "Myriad-bold";
        font-size: 2rem;
        text-align: left;
        margin: 1rem 0 0 0;
        align-self: flex-start;
        color: #5c5c5c;
        &.special {
          font-family: "Myriad-bold";
          &.centro {
            text-align: center;
            width: 100%;
            line-height: 2rem;
          }
        }
      }
  
      .content-wrapper {
        position: relative;
        flex: 1;
        margin: 1rem 0;
        width: 100%;
        overflow: auto;
        text-align: initial;
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding-right: 1.5rem;
        mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%
        );
        -webkit-mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%
        );
        padding: 1rem 0rem;
        align-items: center;
        display: flex;
        flex-flow: column;
        b{
          color: #272727;
        }
        p {
          text-align: center;
          font-size: 1.25rem;
          line-height: 2rem;
          margin-bottom: 2rem;
          font-family: "Lato";
          color: #272727;
          &:last-of-type {
            padding-bottom: 0rem;
          }
          span {
            display: inline-block;
            width: 45%;
            text-align: right;
            &.center1 {
              text-align: center;
              font-family: "Myriad-bold";
              margin-bottom: 1rem;
              border-bottom: 4px solid #aa4b39;
            }
          }
          &.center {
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
            &:last-of-type {
              padding-bottom: 1rem;
            }
          }
          &.left {
            text-align: left;
            padding-right: 0.8rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            &:last-of-type {
              padding-bottom: 1rem;
            }
          }
          &.center1 {
            align-self: center;
            text-align: center;
            &:last-of-type {
              padding-top: 4rem;
            }
          }
        }
        span.center1 {
          text-align: center;
          font-size: 1.5rem;
          font-family: "Myriad-bold";
          margin-bottom: 1rem;
          border-bottom: 4px solid #aa4b39;
        }
        img {
          margin-top: 0rem;
          margin-bottom: 2rem;
          &.img {
            width: 100%;
          }
          &.img1 {
            width: 80px;
            &.sub2 {
              margin: 0 1rem;
            }
          }
          &.img2 {
            width: 100%;
          }
          &.img3 {
            width: 100%;
          }
          &.imgw {
            width: 100px;
          }
          &.imgcm {
            width: 100px;
          }
        }
      }
    }
  }
  </style>
  