<template>
    <div class="reproductor">
        <video class="video" controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" disablePictureInPicture playsinline src="img/Ardennais_c.mp4"></video>
        <button class="close round-btn" @click="closeModal">
            <img
            src="img/02_generales/cerrar.png"
            alt="close button"
            />
        </button>
    </div>
  </template>
  
  <script>
  //const $ = require('jquery');
  import { Analytic } from "./../../public/js/analytics/Analytic.js";

  var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Reproductor",
});
  
  export default {
    name: "reproductor",
    data() {
      return {
        
      };
    },
    mounted() {
      analytic.registerEvent("Video Quinta", "open");
    },
    methods: {
      
      closeModal() {
        this.$parent.closeReproductor();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .reproductor {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .video{
        width: 100%;
    }

    video::-webkit-media-controls-volume-slider {
        display:none;
    }

    video::-webkit-media-controls-mute-button {
        display:none;
    }
  
    .pulse{
      animation: pulse 2s linear infinite;
    }
  
    .close {
      right: 1rem;
      left: unset;
    }
  }
  
  @keyframes pulse{
    0%{
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    50%{
      transform: scale(1.3);
      -webkit-transform: scale(1.3);
    }
    100%{
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  </style>
  