import Vue from "vue";
import VueRouter from "vue-router";

import Route from "../views/Route.vue";
import NextRoute from "../views/NextRoute.vue";
import Map from "../views/Map.vue";
import Scanner from "../views/Scanner.vue";
import Challenge from "../views/Challenge.vue";
import Challenge2 from "../views/Challenge2.vue";
import Rating from "../views/Rating.vue";
import OnBoarding from "../views/OnBoarding.vue";
import Complete from "../views/Complete.vue";
import LastSpot from "../views/LastSpot.vue";
import Discover from "../views/Discover.vue";
import Rewards from "../views/Rewards.vue";
import Help from "../views/Help.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/OptionLoader.vue"),
  },
  {
    path: "/route",
    name: "Route",
    component: Route,
  },
  {
    path: "/nextRoute",
    name: "NextRoute",
    component: NextRoute,
  },
  {
    path: "/lastSpot",
    name: "LastSpot",
    component: LastSpot,
  },
  {
    path: "/complete",
    name: "Complete",
    component: Complete,
  },
  {
    path: "/discover",
    name: "Discover",
    component: Discover,
  },
  {
    path: "/challenge",
    name: "Challenge",
    component: Challenge,
  },
  {
    path: "/challenge2",
    name: "Challenge2",
    component: Challenge2,
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
  },
  {
    path: "/scanner",
    name: "Scanner",
    component: Scanner,
  },
  {
    path: "/rating",
    name: "Rating",
    component: Rating,
  },
  {
    path: "/onboarding",
    name: "OnBoarding",
    component: OnBoarding,
  },
  {
    path: "/rewards",
    name: "Rewards",
    component: Rewards,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/:id",
    name: "HomeId",
    component: () => import("@/components/OptionLoader.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
