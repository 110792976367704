<template>
  <div class="sidebar">
    <transition :name="withAnimation ? 'slide' : 'fade'">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <ul>
          <li
            v-for="section in sections"
            v-bind:key="section.id"
            class="sidebar-opt"
          >
            <a @click.prevent="selectSection" :data-section="section.name">
              {{ $t(section.localized) }}
            </a>
          </li>
        </ul>
      </div>
    </transition>

    <button class="round-btn sidebar-button" @click="toggleSidebar">
      <img
        v-if="!isPanelOpen"
        src="img/02_generales/menu@2x.png"
        alt="open sidebar button"
      />
      <img
        v-if="isPanelOpen"
        src="img/02_generales/back.png"
        alt="close sidebar button"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  created() {
    this.$root.$on("initSidebarWith", (sec) => {
      this.currentSection = sec;
    });
    this.$root.$on("forceSidebar", this.forceSidebar);
  },
  beforeDestroy() {
    // Remove the event listener added in `created`
    this.$root.$off("initSidebarWith");
    this.$root.$off("forceSidebar");
  },
  data: () => ({
    isPanelOpen: false,
    withAnimation: true,
    currentSection: null,
    sections: [
      {
        id: 1,
        name: "introduction",
        localized: "s-introduction",
      },
      {
        id: 4,
        name: "warnings",
        localized: "s-warnings",
      },
      {
        id: 6,
        name: "discover",
        localized: "s-discover",
      },
      {
        id: 5,
        name: "about",
        localized: "s-about",
      },
    ],
  }),
  methods: {
    toggleSidebar() {
      this.withAnimation = true;
      this.isPanelOpen = !this.isPanelOpen;
      this.$root.$emit("updateSidebarStatus", this.isPanelOpen);
    },
    forceSidebar(animated) {
      this.withAnimation = animated;
      this.isPanelOpen = true;
      setTimeout(() => {
        this.withAnimation = true;
      }, 200);
    },
    selectSection(evt) {
      if (evt.target.classList.contains("current")) {
        return;
      }

      this.currentSection = evt.target.dataset.section;
      this.$emit("changeToSection", this.currentSection);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.sidebar-panel {
  position: absolute;
  overflow-y: auto;
  background-color: var(--app-bg);
  left: 0;
  top: 0;
  padding: 4rem 2rem;
  width: 100%;
  height: 100vh;
  z-index: 999;
  // mask-image: linear-gradient(
  //   60deg,
  //   rgb(255, 255, 255) 0%,
  //   rgb(255, 255, 255) 0.1%,
  //   rgb(255, 255, 255) 85%,
  //   rgba(255, 255, 255, 0.7) 100%
  // );
  // -webkit-mask-image: linear-gradient(
  //   60deg,
  //   rgb(255, 255, 255) 0%,
  //   rgb(255, 255, 255) 0.1%,
  //   rgb(255, 255, 255) 85%,
  //   rgba(255, 255, 255, 0.7) 100%
  // );

  ul {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "Myriad-bold";
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    list-style: none;
    text-align: left;
    height: 100%;

    li {
      display: inline-block;
      margin: 1rem 0.5rem;

      a {
        display: block;
        position: relative;
        padding: 0.2em 0;
        overflow: hidden;
        color: #5c5c5c;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.2rem;
          background-color: var(--app-orange);
          transform: translate3d(-120%, 0, 0);
          transition: opacity 300ms, transform 300ms;
        }

        &.current,
        &:hover,
        &:focus {
          font-weight: bold;
          &::after {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
}

.sidebar-button {
  z-index: 999;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
</style>
